import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Buefy from "buefy";
import "./assets/scss/app.scss";
import i18n from "./i18n";
import * as VueGoogleMaps from "vue2-google-maps";
import { init } from "emailjs-com";

init("user_LSXAEZg7w7QlFDSsB5Lxp");
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCm5Y3IjwtKGQH0DGXxunkc9NJ8MkkZBCc",
    libraries: "places"
  }
});

Vue.use(Buefy);
Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount("#app");
