<template>
  <div id="app">
    <router-view />
    <NavVue />    
    <Footer />
  </div>
</template>

<script>
import NavVue from "./components/Nav.vue";
import Footer from "./components/Footer.vue";
export default {
  components: {
    NavVue,
    Footer
  },
   methods: {
    /*onScroll() {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition < 0) {
        return;
      }
      // Stop executing this function if the difference between
      // current scroll position and last scroll position is less than some offset
      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
        return;
      }
      this.showNavbar = currentScrollPosition < this.lastScrollPosition;
      this.lastScrollPosition = currentScrollPosition;
    }, */

    switchLocale(item) {
      this.$i18n.locale = item;
    }
  }
};
</script>

<style lang="scss">
@font-face {
  font-family: "Gilroy";
  src: local("Gilroy"), url(./assets/Gilroy-Light.otf) format("opentype");
}

#app {
  font-family: Gilroy;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;  

  .navbar-menu.is-active {
    border-radius: 50px 15px;
    background: rgba(255, 255, 255, 0.7);
  }
  section.section {
    
    
    justify-content: center;
    align-items: center;
  }
  a:hover {
    color: darkcyan;
  }
  a{
    color:darkcyan;
  }
}
#content {
  
  
  white-space: pre-wrap;
}
</style>
