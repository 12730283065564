<template>
  <div class="mx-6 my-6">
    <div>
      <div class="is-divider"></div>
    </div>
    <div class="mx-5">
      <div class="level mx-5">
        <figure class="level-item mx-2">
          <img
            :src="require('@/assets/Logos\ e\ ilustraciones/Icons-04.png')"
            width="200"
          />
        </figure>
        <div class="level-item mx-2">
          <p class="has-text-left">
            @ FECMX 2020 <br />
            {{ $t("rights") }}
          </p>
        </div>
        <div class="level-item has-text-left mx-2">
          <ul>
            <li>
              <router-link class="has-text-black" to="/">{{
                $t("homeX")
              }}</router-link>
            </li>
            <!--li><router-link class="has-text-black" to="/privacy">{{$t("privacy")}}</router-link></li-->
            <li>
              <router-link class="has-text-black" to="/sitemap">{{
                $t("sitemap")
              }}</router-link>
            </li>
          </ul>
        </div>
        <div class="level-item has-text-left mx-2">
          <p>Powered by MIMICA</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style></style>
