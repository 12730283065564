<template>
  <div>
    <div class="has-bg-img">
    <section class="hero is-fullheight">
      <div class="hero-head"></div>
      <div class="hero-body">
        <div class="container has-text-right has-text-break">
          <h1 class="title has-text-primary-dark my-6 is-size-2-mobile">
            {{ $t("homeTitle") }}
          </h1>
          <b-button
            size="is-medium"
            rounded="true"
            class="button is-text"
            @click="isImageModalActive = true"
          >
            <container class="level">
              <h2 class="subtitle level-item mx-6 my-1 mb-2 is-size-3-mobile">
                {{ $t("homeTitleVideo") }}
                <b-icon
                  icon="chevron-right-circle-outline"
                  size="is-medium"
                  class="level-item ml-2 mt-1"
                ></b-icon>
              </h2>
            </container>
          </b-button>
          <b-modal v-model="isImageModalActive" class="">
            <div class="videowrapper has-text-centered">              
            <iframe
              width="600"
              height="400"
              frameborder="0"              
              src="https://www.youtube.com/embed/CKIT7ddWT0A"
              webkitallowfullscreen mozallowfullscreen allowfullscreen
            ></iframe></div>
          </b-modal>
        </div>
      </div>
      <div class="hero-foot"></div>
    </section>
    </div>
   
    <div class="tile is-ancestor mx-6 mt-6">
      <div class="tile is-vertical is-6">
        <article class="mx-6">
          <h1
            class="title my-1 has-text-primary-dark has-text-right has-text-break"
          >
            {{ $t("home1") }}
          </h1>
          <p class="subtitle my-4 has-text-right">
            {{ $t("home1a") }}
          </p>
          <div class="has-text-right">
            <b-button
              tag="router-link"
              to="/services"
              size="is-medium"
              rounded="true"
              class="is-white is-focused"
            >
              <container class="level">
                <span class="level-item mx-3"
                  >{{ $t("learnmore")
                  }}<b-icon
                    icon="chevron-right-circle-outline"
                    size="is-medium"
                    class="level-item ml-2 mt-1"
                  ></b-icon
                ></span>
              </container>
            </b-button>
          </div>
        </article>
      </div>
      <div class="tile is-vertical">
        <article class="mx-6">
          <figure>
            <img
              class="my-6"
              :src="require('@/assets/Home/IMG_9087.jpg')"
              width="600"
            />
          </figure>
        </article>
      </div>
    </div>
    <div class="tile is-ancestor mx-6 mt-6">
      <div class="tile is-vertical is-6">
        <article class="mx-6">
          <figure>
            <img
              class="my-6 mx-6"
              :src="require('@/assets/Home/IMG_8507.jpg')"
              width="600"
            />
          </figure>
        </article>
      </div>
      <div class="tile mx-6 is-vertical">
        <article>
          <h1
            class="title my-1 has-text-primary-dark has-text-left has-text-break"
          >
            {{ $t("home3") }}
          </h1>
          <p class="subtitle my-4 has-text-left">
            {{ $t("home3a") }}
          </p>
          <div class="has-text-left">
            <b-button
              tag="router-link"
              to="/global"
              size="is-medium"
              rounded="true"
              class="is-white is-focused"
            >
              <container class="level">
                <span class="level-item mx-4"
                  >{{ $t("learnmore")
                  }}<b-icon
                    icon="chevron-right-circle-outline"
                    size="is-medium"
                    class="level-item ml-2 mt-1"
                  ></b-icon
                ></span>
              </container>
            </b-button>
          </div>
        </article>
      </div>
    </div>
    <section class="hero is-small">
      <figure class="crop">
        <img :src="require('@/assets/Home/IMG_9213.jpg')" alt="" />
      </figure>
    </section>
    <div class="tile is-ancestormx-6 mt-6">
      <div class="tile"></div>
      <div class="tile is-vertical is-6">
        <article class="mx-6">
          <h1
            class="title my-1 has-text-primary-dark has-text-center has-text-break"
          >
            {{ $t("home2") }}
          </h1>
          <p class="subtitle my-4 has-text-center">
            {{ $t("home2a") }}
          </p>
          <div class="has-text-center">
            <b-button
              tag="router-link"
              to="/team"
              size="is-medium"
              rounded="true"
              class="is-white is-focused"
            >
              <container class="level">
                <span class="level-item mx-3"
                  >{{ $t("learnmore")
                  }}<b-icon
                    icon="chevron-right-circle-outline"
                    size="is-medium"
                    class="level-item ml-2 mt-1"
                  ></b-icon
                ></span>
              </container>
            </b-button>
          </div>
        </article>
      </div>
      <div class="tile"></div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  data() {
    return {
      isImageModalActive: false,
      isCardModalActive: false
    };
  }
};
</script>
<style scoped>
.has-bg-img {
  background-image: url("~@/assets/Home/IMG_9585-Editar.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: scroll;
}
p {
  white-space: pre;
}
.title {
  word-break: break-word;
  white-space: pre;
}
.crop {
  width: 100%;
  height: 35vw;
  overflow: hidden;
}

.crop img {
  margin: -9vw 0 0 0;
}

@media screen and (max-width: 600px) {
  .crop {
    
    height: 50vw;
    overflow: hidden;
  }
}
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
